<template>
<div class="about-logo">
    保险增值服务平台
    <!-- <img src="@/assets/img/about/logo.png"/> -->
</div>
<div class="about-sp"></div>
<div class="about-list" v-html="model.desc" style="padding-left:1rem;padding-right: 1rem;">
    
</div>

<div class="service">
		<div class="service-title2">{{ getTitle1() }}</div>
		<div class="service-title3">{{getTitle2()}}</div>
	</div>
</template>

<script setup>
import {isJSON,isNull,showMessage,nullStr,disLabel,getTitle1,getTitle2} from '../../common/utils.js'
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import http from '../../common/bxhttp.js';
import {useRoute,useRouter} from 'vue-router'
let route=useRoute()
let model=reactive({
  title:"",
  desc:""
})
http.post("/art/web/articledetail",{artCode:"1405"}).then((res)=>{
    if(res.errcode=="0"){
        model.title=res.detail.art_title;
        model.desc=res.detail.art_desc;
    }else{
        showMessage(res.errmsg);
    }
})
</script>

<style scoped lang="scss">
.about-item-point{
        width:0.5rem;
        height:0.5rem;
        background: #3d6eff;
        border-radius: 50%;
        margin-top:0.3rem;
        
    }
.about-item{
    margin:2rem;
    display: flex;
    span{
        font-weight: bold;
    }
    .about-item-div{
        margin-left:1rem;
    }
    
}
.about-logo{
    margin:2rem;
    text-align: center;
    img{
        width:3rem;
    }
}
.about-sp{
    margin:2rem;
    height:1px;
    background:#ebebeb;
}
.service{
  .service-title2{
    text-align: center;
    color: #848E9D;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 4rem;
  }
  .service-title3{
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}
</style>